import React, {useState} from "react"
import { Link } from "gatsby"
import { Col, Row } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import logo from '../assets/img/everhealth_logo_black.png';

const Layout = ({ location, title, children, bio }) => {
	const rootPath = `${__PATH_PREFIX__}/`
	const isRootPath = location.pathname === rootPath
	const [menuOpen, setMenuOpen] = useState(false);

	// if (isRootPath) {
	// 	logo = (
	// 	<Link className="header-link-home" to="/">
	// 		{title}
	// 	</Link>
	// 	)
	// } else {
	// 	logo = (
	// 	<Link className="header-link-home" to="/">
	// 		{title}
	// 	</Link>
	// 	)
	// }

	const openMenu = () => {
		setMenuOpen(true);
	}

	const closeMenu = () => {
		setMenuOpen(false);
	}

  	return (
    	<div>
      		<header className="global-header">
				<div className="global-wrapper" data-is-root-path={isRootPath}>
					<Row>
						<Col xs={16} sm={7} md={7} lg={7} xl={7}>
							<div className="logo">
								<Link className="header-link-home" to="/"><img src={logo} /></Link>
							</div>
						</Col>
						<Col xs={24} l={17} xl={17}>
							<nav>
								<ul>
									<li><Link to="/blogg">Blogg</Link></li>
									<li><Link to="/recept">Recept</Link></li>
									<li><Link to="/podcast">Podd</Link></li>
									<li><Link to="/snabbhack">Snabbhack</Link></li>
									<li><Link to="/om-oss">Om</Link></li>
								</ul>
							</nav>
						</Col>
					</Row>
					<div className="mobile-menu-hamburger">
						<div onClick={openMenu}><MenuOutlined style={{ fontSize: 32 }} /></div>
					</div>
					{menuOpen && <div className="mobile-menu">
						<div className="mobile-menu-close" onClick={closeMenu}><CloseOutlined style={{ fontSize: 32 }} /></div>
						<ul>
							<li><Link to="/">Hem</Link></li>
							<li><Link to="/blogg">Blogg</Link></li>
							<li><Link to="/recept">Recept</Link></li>
							<li><Link to="/podcast">Podd</Link></li>
							<li><Link to="/snabbhack">Snabbhack</Link></li>
							<li><Link to="/om-oss">Om</Link></li>
						</ul>
					</div>}
				</div>
			</header>
      		<main>
				{children}
			</main>
      		<footer>
			  	<div className="global-wrapper" data-is-root-path={isRootPath}>
				  	© {new Date().getFullYear()}, Everhealth
				</div>
			</footer>
    	</div>
  	);
}

export default Layout
